#modal-back{
    position: absolute;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    top: 82px;
    left: 0;
    z-index: 9;
    background-color: rgba(50, 50, 50, 0.5);
    animation: appear ease-in-out 0.2s;
    border: none;
}

#menu-container{
  position: absolute;
  top: 74px;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 70vw;
  background-color: #00001a;
  z-index: 10;
  animation: slide-in-left 0.3s ease-out;
}

#menu-content-container{
  height: 100%;
  width: 85%;
  margin : 40px 20px 20px 20px;
  display : flex;
  flex-direction : column;
  gap: 20px;
}

.indicator-menu-container{
  display : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  color: white;
  z-index: 10;
}

#delete{
  transition: all .3s;
  background-color: red;
  border-radius: 5px;
}

.indicator-menu-container > * {
  z-index: 8;
}

.indicator-menu{
  font-weight: 600;
}

.menu-index{
  border-radius: 5px;
  min-width: 25px;
  padding: 5px;
  text-align: center;
}

#search-bar-styler{
  width: 100%;
}

#buttons-menu{
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
}

#edit-countries{
  align-self: center;
  background-color: transparent;
  color: white;
  border: 1px solid white ;
  border-radius: 20px;
  padding: 5px 0px 5px 0px;
  width: 70%;
}

#connect-buttons{
  display : flex;
  justify-self: flex-start;
  align-self: center;
  gap: 10px;
}

.flag-menu{
  margin-bottom: -3px;
  margin-right: 5px;
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
    opacity: 0;
    display: none;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  40% {
    -webkit-transform: translate(-1px, 1px);
            transform: translate(-1px, 1px);
  }
  55% {
    -webkit-transform: translate(-1px, -1px);
            transform: translate(-1px, -1px);
  }
  70% {
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
  }
  85% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
