#footer-container{
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  padding-bottom: 10px;
  background-color: transparent;
  padding-top: 10px;
  width: 100vw;
  border-top: 1px white solid;
}

#paragraph-footer{
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  margin: 5px 50px 5px 50px;
  gap: 3px;
}

#paragraph-footer > span{
  font-size: 14px;
  text-align: center;
}

.footer-link{
  font-size: 20px;
  font-weight: 600;
  color: aqua;
}

.footer-link:visited{
  color: rgb(213, 103, 121);
}

.footer-highlight{
  font-weight: 500;
  text-decoration: underline;
  color: white;
}

#logo-container{
  margin-top: 5px;
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  gap: 10px;
}

.logo-footer{
  text-decoration: none;
  color: white;
  font-size: 50px;
}

#link-container{
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items : center;
  gap: 25px;
}

@media screen and (max-width : 500px) {
  .footer-link{
    font-size: 16px;
    font-weight: 500;
  }
  #paragraph-footer > span{
    font-size: 14px;
  }
  #paragraph-footer{
    margin-left: 20px;
    margin-right: 20px;
    gap: 5px;
  }
  #footer-container{
    padding-top: 10px;
    padding-bottom: 0px;
  }
  #footer-container > *{
    margin-top:0;
    margin-bottom:0;
  }
  #link-container{
    flex-direction : column;
    gap: 0px;
  }
}