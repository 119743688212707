#nav-top-container{
  margin-bottom: 20px;
  margin-top: 15px;
}

#mobile-header{
  padding-top: 20px;
  border-bottom: none;
}

.navbar-header{
  margin-top: 0;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid white;
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
}

#navbar-container-mobile{
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  overflow: hidden;
  background-color: #00001a;
  z-index: 5;
}

#ham-menu{
  margin-left: 10px;
  font-size: 30px;
  background: none;
  color: white;
  border: none;
}

@media screen and (max-width:600px) {
  #nav-top-container{
    margin-top: 0px;
  }
  
}