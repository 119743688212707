.paragraph{
  text-align: justify;
  line-height: 1.5;
  font-size: 18px;
}

.paragraph-container{
  min-height: 80vh;
  width: 80vw;
  display : flex;
  flex-direction : column;
  justify-content : center;
  align-items : center;
  gap: 20px;
  margin: 40px 0px;
}

.formula-transparency {
  text-align: center;
}

.formula-container {
  font-weight: 400;
  padding: 20px 14px;
  background-color: #242424;
  border-radius: 10px;
  margin: 10px;
  border: #373737 solid 2px;
}

.index-txt {
  font-size: 20px;
  font-weight: 500;
  padding: 3px;
  border-radius: 4px;
}

#p {
  background-color: #03bb17;
}

#n {
  background-color: #ab101c;
}

#nu {
  background-color: #c8c813;
}

#m {
  background-color: #b555ff;
}
