#graph-container{
  margin: 0 10px 40px 10px;
  padding: 20px 60px 20px 60px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-color: #aaaaaa1a;
  border-radius: 15px;
}

#graph-header{
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  color: #e3e3e3;
}

@media screen and (max-width: 600px) {
  #graph-container{
    margin: 0 10px 20px 10px;
    padding: 10px 20px 10px 20px;
  }

  #graph-header{
    font-size: 22px;
  }
}