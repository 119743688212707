html {
  overflow: hidden;
  height: 100%;
  /* background-image: linear-gradient(to right bottom, #1d0030, #17022e, #12022b, #0c0328, #060324, #030a26, #011027, #001428, #001c2d, #00232a, #00281e, #002c0c); */
  background-color: rgb(0, 2, 26);
  background-image:
    radial-gradient(at 100% 6%, hsla(308, 100%, 15%, 0.63) 0px, transparent 40%),
    radial-gradient(at 47% 87%, hsla(189, 100%, 15%, 0.59) 0px, transparent 40%),
    radial-gradient(at 21% 68%, hsla(212, 100%, 15%, 0.77) 0px, transparent 40%),
    radial-gradient(at 96% 96%, hsla(116, 100%, 10%, 0.79) 0px, transparent 40%);
  color: white;
}

body {
  height: 100%;
  overflow-x: hidden;
}

body,
html {
  font-family: 'Chillax', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
}

/* Make the scroll snap happen only on desktop */
@media only screen and (min-width: 800px) {
  body {
    scroll-snap-type: y mandatory;
  }
}

.master {
  width: 100vw;
  font-size: calc(4.5vw);
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  word-spacing: 7px;
  letter-spacing: 1px;
  border: none;
}

#title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub-master {
  text-align: center;
  color: orange;
}

span#io {
  color: rgb(1, 205, 205);
}

div#global-map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min(100% - 15px, 800px);
  margin-inline: auto;
}

div#global-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

#loader-placeholder {
  position: relative;
  height: 75vh;

}

#loader-container {
  position: fixed;
  top: 65%;
  left: 50%;
  height: 75vh;
  transform: translate(-50%, -50%);
}

.dna-wrapper {
  height: 200px;
}

#keyword-title {
  padding: 5px 2vw 5px 2vw;
  border-radius: 10px;
  color: white;
}

#sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mobile-title {
  font-size: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.mobile-padding {
  margin-top: 60px;
}

#desktop-title {
  margin-top: 22px;
}

#home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}