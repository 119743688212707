#map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 3px white solid;
  border-radius: 20px;
  -webkit-animation: slide-in-blurred-bottom 0.5s ease-in-out both;
  animation: slide-in-blurred-bottom 0.5s ease-in-out both;
}

#mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-top: 2px white solid;
  border-bottom: 2px white solid;
  -webkit-animation: slide-in-blurred-bottom 0.5s ease-in-out both;
  animation: slide-in-blurred-bottom 0.5s ease-in-out both;
}

#map path:focus {
  outline: none;
}

path:hover {
  cursor: pointer;
}

#space-container {
  min-height: 280px;
  position: relative;
}

.selected-geo {
  opacity: 0.6;
  stroke: black;
  stroke-width: 2;
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-opacity: 0;
    opacity: 0;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}